import styled, { css } from 'styled-components';
import breakpoints from '../../../styles/settings/breakpoints';
import { lineHeight } from '../../../styles/settings/fonts';
import { margins } from '../../../styles/settings/spacing';
import { size, weight } from '../../../styles/settings/typography';
import { above } from '../../../styles/tools/media';
import { smBodyLight } from '../Typography/index.styled';
import colors from '../../../styles/settings/colors';

interface TitleProps {
	isCenter?: boolean
	gdprLink?: boolean
}

const RichTextBlock = styled(smBodyLight)<TitleProps>`
& a {
	color: var(--primaryForeground);
	text-decoration: none;
	${props =>
		props.gdprLink &&
		css`
			display: inline-block;
			position: relative;
			color: inherit;
			margin: 0 !important;

			&:before {
				content: '';
				display: block;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: ${colors.monochrome.grey75};
				transition: width 0.3s ease-in-out;
			}

			&:hover,
			&:active,
			&:focus {
				&:before {
					width: 0;
				}
			}
		`}
}

& h2 {
	font-size: min(max(${size[16]}, 1.5625vw), ${size[20]});
	line-height: min(max(${lineHeight[24]}, 2.5vw), ${lineHeight[32]});

	font-weight: ${weight[400]};

	margin-bottom: ${margins[32]};

	${above(
		breakpoints[1280],
		() => css`
			font-size: min(max(${size[20]}, 1.875vw), ${size[24]});
			line-height: min(max(${lineHeight[32]}, 2.8125vw), ${lineHeight[36]});

			margin-bottom: ${margins[48]};
		`
	)}
}

& p {
	margin-bottom: ${margins[24]};

	${above(
		breakpoints[1280],
		() => css`
			margin-bottom: ${margins[32]};
		`
	)}
	${above(
		breakpoints[960],
		() => css<TitleProps>`
			${props =>
				props.isCenter &&
				css`
					max-width: none;
					text-align: center;
					grid-column: 4 / span 6;
				`}
		`
	)}
	${props =>
		props.gdprLink &&
		css`
			font-size: min(max(${size[12]}, 1.25vw), ${size[16]});
		`}
}

& b {
	font-weight: ${weight[400]};
}
`;

export { RichTextBlock };
